import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout/Layout';
import { Product } from '../../modules/product/product';
import Detail from '../../routes/product/Detail';

export const query = graphql`
  query ($productId: String!) {
    product: catalogue(id: { eq: $productId }) {
      ...CatalogueProductDetail
    }
  }
`;

type Props = {
  data: {
    product: Product;
  };
  pageContext: {
    variantId: string;
  };
};

const Page: React.FC<Props> = ({ data, pageContext }) => {
  const variant = data.product.variants.find(
    (_) => _.id === pageContext.variantId
  );

  if (!variant) {
    throw new Error(`Variant ${pageContext.variantId} not found.`);
  }

  const name = variant.name
    ? `${data.product.name} - ${variant.name}`
    : data.product.name;

  return (
    <Layout
      title="La Poupée qui aime la planète !"
      crumbs={[
        { name: 'Accueil', path: '/' },
        { name: 'Shop', path: '/shop/' },
        { name: name, path: `/shop/${variant.canonical}/` },
      ]}
    >
      <SEO
        title={`${name} | Poupée Odette`}
        description={data.product.description}
      />

      <Detail product={data.product} variant={variant} />
    </Layout>
  );
};

export default Page;
